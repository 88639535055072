import React from 'react';
import {Container} from 'reactstrap';
import {ImageLogo} from 'components/Images';
import styles from './PageFooter.module.scss';
import cx from 'classnames';
import {
  APP_STAGE,
  CDN_FILE_URL,
  OPEN_LANDING,
  SHOW_CB_SEARCH_LINK,
} from 'constants/environment';
import {Link} from 'react-router-dom';
import {includes} from 'lodash';
import {CERTCHECK_STATEMENT_FILE} from 'constants/file-url';
import urljoin from 'url-join';

PageFooter.propTypes = {};

function PageFooter(props) {
  const {className, fluid} = props;

  const date = new Date();
  const year = date.getFullYear();
  const env = ['development', 'staging', 'alpha'];
  const isDev = includes(env, APP_STAGE);

  const isOpenLanding = OPEN_LANDING === '1' || OPEN_LANDING === 1;

  const showContent = isDev || isOpenLanding;
  const showCBSearchLink =
    SHOW_CB_SEARCH_LINK === '1' || SHOW_CB_SEARCH_LINK === 1;

  return (
    <footer className={cx('footer border-top position-relative', className)}>
      <Container fluid={fluid}>
        <div className={styles['landing-footer-section']}>
          <div className={styles['landing-footer-section__text']}>
            <div>&copy; United Kingdom Accreditation Service Ltd {year}</div>

            <div className={styles['landing-footer-section__text__inner']}>
              <div>
                <a href="https://www.ukas.com" rel="noreferrer">
                  UKAS.com
                </a>
              </div>
              {showContent && (
                <div>
                  <Link to="/contact">Contact us</Link>
                </div>
              )}
              {showCBSearchLink && (
                <div>
                  <Link to="/certification-body">
                    Participating Certification Bodies
                  </Link>
                </div>
              )}
              <div>
                <a
                  download={CERTCHECK_STATEMENT_FILE}
                  href={urljoin(
                    CDN_FILE_URL,
                    'assets/t/pdf/',
                    CERTCHECK_STATEMENT_FILE
                  )}
                >
                  CertCheck Security Statement
                </a>
              </div>
            </div>
          </div>
          <div>
            <ImageLogo color="dark" />
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default PageFooter;
